<template>
<div>
    <b-row>
        <b-col cols="12">
            <b-card class="my-card m-0">
                <b-table 
                striped
                small
                id="myTables"
                bordered
                selectable
                selected-variant="primary"
                select-mode="single"
                responsive="sm"
                :items="villages"
                :fields="fields"
                head-row-variant="secondary"
                @row-clicked="clicked"
                @row-dblclicked="editForm"
                >
                <template #cell(checkbox)="row">
                    <b-form-checkbox
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="row.toggleDetails"
                    >
                    </b-form-checkbox>
                </template>    
                <template #cell(polygon)="data">
                    {{ data.item.polygon ? 'Да' : 'Нет' }}
                </template>
                <template #cell(latlng)="data">
                    {{ data.item.lat ? [data.item.lat + ',' + data.item.lng] : "Нет" }}
                </template>
            </b-table>
        </b-card>
    </b-col>
    </b-row>
</div>
</template>
<script>
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
    props: ['villages'],
    data(){
        return {
        fields: [ 
            { key: 'checkbox', label: '', thStyle: {width: '50px'}},
            { key: 'id', label: 'Id', sortable: true, thStyle: {width: '50px'}},
            { key: 'name', label: 'Населенный пункт', sortable: true, thStyle: {width: '150px'}},
            { key: 'polygon', label: 'Полигон', sortable: true, thStyle: {width: '150px'}},
            { key: 'latlng', label: "Центр пункт населения", sortable: true, thStyle: {width: '100px'}},
            { key: 'village_type_name', label: 'Название деревни', sortable: true, thStyle: {width: '150px'}},
            { key: 'city_name', label: 'Название города', sortable: true, thStyle: {width: '150px'}},
            { key: 'created_at', label: 'Создан', sortable: true, thStyle: {width: '150px'}}
            ],
        }
    },
    mounted(){
        resizeable()
    },
    methods:{
        clicked(item, index, event){
            this.$store.state.filterPlus.tableIndex = index
            cellSelect()
        },
        editForm(id) {
            this.$emit('editForm', id.id)
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
</style>